<template>
  <div class="unit-card row justify-content-start align-content-between">
    <div class="col-12" @click="$emit('body-click')">
      <!-- ============================= Header ============================ -->
      <div class="col-12 d-flex header">
        <div class="plot-wrapper">
          <div class="plot">{{ unit.plot }}</div>
        </div>
        <div class="street-wrapper ml-1 p-1">
          <!-- Street -->
          <p v-if="!$isStringEmpty(unit.street)" class="street">
            {{ unit.street }}
          </p>
          <p v-else class="street greyed">No street name yet</p>
        </div>
        <div :class="{ 'ml-1': $slots['top-right'] }">
          <slot name="top-right"></slot>
        </div>
      </div>

      <!-- ============================ Content ============================ -->
      <div class="content row align-content-between px-2 pb-2">
        <div class="col-12">
          <!-- Status -->
          <div class="d-flex justify-content-end">
            <UnitStatus :status="unit.status"></UnitStatus>
          </div>
          <!-- Project, Unit Type, Floor Plan -->
          <div
            v-if="showProject || showUnitType || showFloorPlan || showDeveloper"
            class="mb-2"
          >
            <!-- Project -->
            <p v-if="showDeveloper" class="data">
              <span class="bold greyed mr-1">Developer</span>
              {{ unit.project.developer.name }}
            </p>
            <!-- Project -->
            <p v-if="showProject" class="data">
              <span class="bold greyed mr-1">Project</span>
              {{ unit.project.name }}
            </p>
            <!-- Unit Type -->
            <p v-if="showUnitType" class="data">
              <span class="bold greyed mr-1">Unit Type </span>
              {{ unit.projectUnitType.name }}
            </p>
            <!-- Floor Plan -->
            <p v-if="showFloorPlan" class="data">
              <span class="bold greyed mr-1">Floor Plan </span>
              {{ unit.projectUnitFloorPlan.name }}
            </p>
          </div>
          <!-- Sale Price -->
          <div v-if="unit.salePrice != 0" class="mb-1">
            <p class="price">
              RM {{ numberWithCommas(parseInt(unit.salePrice)) }}
            </p>
            <p class="price-per-unit">
              RM{{ unit.salePricePerUnit }}/{{
                unit.projectUnitFloorPlan.areaUnitType
              }}
            </p>
          </div>
          <div v-if="unit.phase" class="phase">
            {{ unit.phase.name }}
          </div>
          <!-- Booking Fee -->
          <div class="mt-1">
            <p>
              <i class="fas fa-dollar-sign mr-1"></i>
              <span class="font-bold">RM {{ parseInt(unit.bookingFee) }}</span>
              Booking Fee
            </p>
          </div>
          <!-- Orienatation -->
          <div v-if="unit.orientation">
            <p>
              <i class="fas fa-compass mr-1 mb-1"></i>{{ unit.orientation }}
            </p>
          </div>
          <!-- Tenure Type -->
          <div v-if="unit.tenureType.length > 0">
            <p>
              <i class="fas fa-scroll mr-1 mb-1"></i>
              <span
                v-for="(tenure, index) in unit.tenureType"
                :key="index"
                class="append-comma"
              >
                {{ tenure }}
              </span>
            </p>
          </div>
          <!-- Land & Built-up Area -->
          <div>
            <p>
              <span class="bold greyed mr-1">Built-up</span>
              {{ unit.builtUpArea }}
              {{ unit.projectUnitFloorPlan.areaUnitType }}
            </p>
            <p>
              <span class="bold greyed mr-1">Land</span>
              {{ unit.landArea }} {{ unit.projectUnitFloorPlan.areaUnitType }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- ============================= Actions ============================= -->
    <div class="col-12 actions row justify-content-end px-2 my-2">
      <!-- View Gallery -->
      <fd-button
        v-if="unit.vrLink !== ''"
        class="actions--gallery main bordered mr-1"
        @click.stop="viewVR"
      >
        <em class="font-bold">VR</em>
      </fd-button>
      <fd-button
        v-if="unit.gallery.length > 0"
        class="actions--gallery main bordered mr-1 flex-grow-1"
        @click.stop="viewGallery"
      >
        View Gallery
      </fd-button>
      <div class="actions--manage ml-1">
        <dropdown-button>
          <template #buttonContent>
            <i class="fas fa-ellipsis-v"></i>
          </template>
          <div class="card">
            <list-item
              class="text-left"
              @click="copyToClipboard(projectId, unit.id)"
              >
              <i class="fas fa-copy mr-2"></i>
              Copy url to clipboard
            </list-item>
            <div v-if="canEdit">
              <!-- Change Status/Edit -->
              <list-item
                class="text-left"
                @click="$emit('change-status', unit)"
              >
                <i class="fas fa-right-left mr-2"></i>
                Change Status
              </list-item>
              <list-item class="text-left" @click="$emit('edit', unit.id)">
                <i class="fas fa-pen mr-2"></i>
                Edit
              </list-item>
              <!-- Banker/Lawyer Allocation -->
              <hr />
              <list-item
                class="text-left"
                @click="$emit('assign-banker', unit)"
              >
                <i class="fas fa-user-gear mr-2"></i>
                Assign Bankers
              </list-item>
              <list-item
                class="text-left"
                @click="$emit('assign-lawyer', unit)"
              >
                <i class="fas fa-user-gear mr-2"></i>
                Assign Lawyers
              </list-item>
            </div>

            <!-- Remove -->
            <div v-if="canDelete">
              <hr />
              <list-item
                class="text-left"
                @click="$emit('delete', unit.id, `${unit.plot}`)"
              >
                <i class="fas fa-trash mr-2"></i>
                Delete
              </list-item>
            </div>
          </div>
        </dropdown-button>
      </div>
    </div>

    <!-- Gallery Lightbox -->
    <cool-light-box
      :items="galleryImages"
      :index="galleryIndex"
      @close="galleryIndex = null"
      :slideshow="false"
      @click.self.stop
    >
    </cool-light-box>

    <!-- VR Preview -->

    <div v-show="showVR" class="video row justify-content-center mb-4">
      <iframe
        ref="vrPreview"
        :id="unit.id"
        :src="unit.vrLink"
        style="width: 600px; height: 400px"
        allowfullscreen
        @fullscreenchange="vrPreviewFullscreenChange"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { numberWithCommas } from "@/utils/string";
import copy from 'copy-to-clipboard';

export default {
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    ListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    UnitStatus: () => import("@/modules/Project/components/Unit/UnitStatus")
  },
  mixins: [],
  props: {
    projectId: {
      type: [Number, String]
    },
    unit: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canAllocateLawyer: {
      type: Boolean,
      default: false
    },
    canAllocateBanker: {
      type: Boolean,
      default: false
    },
    showProject: {
      type: Boolean,
      default: false
    },
    showUnitType: {
      type: Boolean,
      default: false
    },
    showFloorPlan: {
      type: Boolean,
      default: false
    },
    showDeveloper: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      galleryIndex: null,
      showVR: false,

      numberWithCommas
    };
  },
  computed: {
    galleryImages() {
      if (this.unit.gallery.length > 0) {
        return this.unit.gallery.map((item) => {
          return this.$getFileURL(item);
        });
      } else {
        return [];
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    copyToClipboard(projectId, unitId) {
      const hostname = "https://www.steadee.com";
      copy(`${hostname}/project/${projectId}/unit/${unitId}`);
      Vue.notify({
        group: 'alert',
        title: 'Copied Unit URL to clipboard',
      })
    },
    viewGallery() {
      this.galleryIndex = 0;
    },
    viewVR() {
      this.showVR = true;
      this.$refs.vrPreview.requestFullscreen();
    },
    vrPreviewFullscreenChange() {
      // hides vr in dom if exiting
      if (
        document.fullscreenElement === null ||
        document.fullscreenElement.id !== this.unit.id
      ) {
        this.showVR = false;
      }
    }
  }
};
</script>

<style lang="scss">
.unit-card {
  height: 100%;
  align-content: flex-start;
  align-items: flex-end;
  border: solid 1px #cacaca;
  border-radius: 5px;
  flex-wrap: wrap;

  .plot-wrapper {
    position: relative;
    top: -20px;
    right: -10px;
    margin-right: 10px;
    .plot {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 75px;
      height: 75px;
      padding: 10px;

      font-size: 24px;
      word-break: break-all;
      background-color: $color-accent2;
      color: lighten($color-accent2, 45);
      border-radius: 5px;
      box-shadow: 0px 4px 5px #00000025;
    }
  }

  .street-wrapper {
    flex-grow: 1;
    .street {
      display: inline-block;
      font-size: 16px;
    }
  }

  .content {
    height: auto;
    max-height: 100%;
    flex-grow: 1;
    .price {
      font-size: 18px;
      font-weight: bold;
      color: darken($color-accent2, 20);
    }

    .bold {
      font-weight: bold;
    }
    .greyed {
      color: #505050;
    }

    .phase {
      background: lighten($color-secondary, 45);
      color: $color-secondary;
      padding: 4px 12px;
      border-radius: 50em;
      font-size: 12px;
      margin: 4px 0;
      width: fit-content;
    }
  }

  .actions {
    align-self: flex-end;
    .actions--gallery,
    .actions--manage {
      text-align: center;
      @media #{$breakpoint-down-sm} {
        width: 100%;
        margin: 4px 0;
      }
    }
  }

  .flex-grow-1 {
    flex-grow: 1;
  }
}
</style>
